import React from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"

const siteMap = {
  items: [
    {label: "Leistungen", url: "/#leistungen"},
    {label: "Makler", url: "/#makler"},
    {label: "Referenzen", url: "/#referenzen"},
    {label: "Gratis Bewertung", url: "/#start", prominent: true},
  ]
}

const NotFoundPage = () => (
  <Layout siteMap={siteMap}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
